import React, { useState } from "react"
import {
  Tabs,
  Tab,
  Card,
  Row,
  Col,
  Space,
  Header,
  // IconButton,
  Toggle,
  IconButtonCircle,
} from "uiComponents"
import SpaceBetween from "../../UI/Space/SpaceBetween"
import { FormMaker } from "../../Forms"
import { fields } from "./Schema"
import "./MarketingForm.css"
import { DeviceWrapper } from "../../UI/Devices/Devices"
import { AdBanner } from "./AdBanner"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import { useAddUserAndUserStores } from "firebaseUtils/firebaseOperations"
import { useStateValue } from "state"
// import { statusStyles } from "shoppio-constants/Constants"

const MarketingForm = () => {
  const [formData, setFormData] = useState({})
  const [showWrappedDevices, setShowWrappedDevices] = useState(false)
  const [{ user, userMeta, currentStoreData }, dispatch] = useStateValue()
  const currentStoreId = currentStoreData?.id
  const initialValues = currentStoreData?.marketing

  const history = useHistory()
  console.log(
    "user",
    user,
    "userMeta",
    userMeta,
    "currentStoreId",
    currentStoreId,
  )

  const { busy, handleMarketingAd } = useAddUserAndUserStores(
    user,
    userMeta,
    dispatch,
    currentStoreId,
  )

  const handleSubmit = values => {
    handleMarketingAd(values)
    setFormData({})
  }

  const handleFormChange = data => {
    setFormData(data)
    console.log(data)
  }

  const handleToggleDevices = () => {
    setShowWrappedDevices(!showWrappedDevices)
  }

  // const status = initialValues?.status

  return (
    <div>
      <Tabs>
        <Tab label='New Ads'>
          <Row>
            {showWrappedDevices && (
              <Col sm={12} md={12}>
                <Card>
                  <Row>
                    <>
                      <Col sm={12} md={4}>
                        <div className='center-content'>
                          <DeviceWrapper data={formData} />
                        </div>
                      </Col>
                      <Col sm={12} md={4}>
                        <div className='center-content'>
                          <DeviceWrapper data={formData} type='desktop' />
                        </div>
                      </Col>
                      <Col sm={12} md={4}>
                        <div className='center-content'>
                          <DeviceWrapper data={formData} type='ipad' />
                        </div>
                      </Col>
                    </>
                  </Row>
                </Card>
              </Col>
            )}
            <Col sm={12} md={12}>
              <Card>
                <SpaceBetween>
                  <div style={{ display: "flex", marginRight: "10px" }}>
                    <IconButtonCircle
                      icon='chevron-left'
                      mr='10'
                      onClick={() => history.goBack()}
                    />
                    <div>
                      <Header sm bold>
                        Please provide your marketing ad details
                      </Header>
                      <p>
                        Discover premium elegance with our luxury brand –
                        Explore now and elevate your style!
                      </p>
                    </div>
                  </div>

                  {/* <IconButton
                    small
                    iconColor={statusStyles[status].color}
                    icon={statusStyles[status].icon}
                    style={{
                      backgroundColor: statusStyles[status].color,
                      color: "white",
                    }}
                  >
                    {status}
                  </IconButton> */}
                </SpaceBetween>
                <Space />
                <Col lg={6} md={8} sm={10} xs={12}>
                  <FormMaker
                    fields={fields}
                    validateOnMount
                    handleChange={data => handleFormChange(data)}
                    busy={busy}
                    onSubmit={handleSubmit}
                  />
                  <Header bold>Ad Preview</Header>
                  <Space />
                  <Toggle
                    active={showWrappedDevices}
                    onClick={handleToggleDevices}
                    //   activeLabel={"Hide Wrapped Devices"}
                    //   label={"Show Wrapped Devices"}
                    horizontal
                  />
                </Col>
              </Card>
            </Col>
          </Row>
        </Tab>
        <Tab label='Active Ads'>
          {initialValues?.adActive === true ? (
            <AdBanner
              active
              data={initialValues}
              storeData={currentStoreData}
            />
          ) : (
            <>
              <Header sm bold>
                You have not any active ads
              </Header>
            </>
          )}
        </Tab>
        <Tab label='My Ads'>
          <AdBanner
            editable
            active
            data={initialValues}
            storeData={currentStoreData}
            adStatus
          />
        </Tab>
      </Tabs>
    </div>
  )
}

export default MarketingForm
