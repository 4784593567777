import React, { useState } from "react"
import { FormMaker } from "formComponents"
import {
  productCategories,
  serviceCategories,
  storeBusinessInfoFields,
  storeTypeFields,
  storeLocationFields,
  processes,
} from "shoppio-constants/Constants"
import { Card, Col, Expander, Header, Row, Space } from "uiComponents"
import { useStateValue } from "state"
import { useAddUserAndUserStores } from "firebaseUtils/firebaseOperations"
import { parseDateTime } from "helpers"
import { Logistics, PageLayout } from "appComponents"

const StoreForm = () => {
  const [storeType, setStoreType] = useState("")
  const [{ user, userMeta, currentStoreData, busyProcesses }, dispatch] =
    useStateValue()
  console.log("store data: ", currentStoreData)

  const currentStoreId = currentStoreData?.id
  console.log(currentStoreId)
  const bussinessData = currentStoreData?.businessInfo
  const storeTypeData = currentStoreData?.storeTypeInfo
  const storeAddressData = currentStoreData?.storeAddress

  const lastUpdatedOfBusinessInfo = parseDateTime(bussinessData?.updatedAt)
  const lastUpdatedOfStoreType = parseDateTime(storeTypeData?.updatedAt)
  const lastUpdatedOfStoreAddress = parseDateTime(storeAddressData?.updatedAt)

  const businessInfoStatus = bussinessData?.status
  const storeTypeStatus = storeTypeData?.status
  const storeAddressStatus = storeAddressData?.status

  const getCategoryOptions = () => {
    switch (storeType) {
      case "product":
        return productCategories
      case "service":
        return serviceCategories
      case "both":
        return [...productCategories, ...serviceCategories]
      default:
        return []
    }
  }

  const { busy, handleBusinessInfo, handleStoreType, handleStoreAddress } =
    useAddUserAndUserStores(user, userMeta, dispatch, currentStoreId)

  const handleBusinessInfoSubmit = values => {
    handleBusinessInfo(values)
  }

  const handleStoreTypesSubmit = values => {
    handleStoreType(values)
  }

  const handleStoreAddressSubmit = values => {
    handleStoreAddress(values)
  }

  const fetchingData = busyProcesses.find(
    process => process.id === processes.GET_STORE_DATA.id,
  )

  console.log("busyProcesses", busyProcesses)
  return (
    <>
      <Card noXS notFull>
        <Header md bold>
          Create a Store
        </Header>
        <p>Fill in the required details about your store to get started.</p>
        <>
          <Card>
            <Expander
              step={"1"}
              lastUpdated={lastUpdatedOfBusinessInfo}
              title={"Business Information"}
              stepStatus={businessInfoStatus}
              busy={fetchingData}
            >
              <Space lg />
              <FormMaker
                initialValues={bussinessData}
                fields={storeBusinessInfoFields}
                onSubmit={handleBusinessInfoSubmit}
                busy={busy}
              />
            </Expander>
          </Card>
          <Card>
            <Expander
              step={"2"}
              lastUpdated={lastUpdatedOfStoreType}
              stepStatus={storeTypeStatus}
              title={"Store Type"}
              busy={fetchingData}
            >
              <Space lg />
              <FormMaker
                fields={storeTypeFields(getCategoryOptions, setStoreType)}
                onSubmit={handleStoreTypesSubmit}
                initialValues={storeTypeData}
              />
            </Expander>
          </Card>
          <Card>
            <Expander
              step={"3"}
              lastUpdated={lastUpdatedOfStoreAddress}
              stepStatus={storeAddressStatus}
              title={"Store Location"}
              busy={fetchingData}
            >
              <Space lg />
              <FormMaker
                fields={storeLocationFields}
                onSubmit={handleStoreAddressSubmit}
                initialValues={storeAddressData}
              />
            </Expander>
          </Card>
        </>
      </Card>
      <Space lg />
    </>
  )
}

export default StoreForm

export const StoreFormPage = () => {
  return (
    <PageLayout
      mainContent={
        <>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={9}>
              <StoreForm />
            </Col>
            <Col sm={12} md={12} lg={12} xl={3}>
              <Logistics />
            </Col>
          </Row>
        </>
      }
    />
  )
}
