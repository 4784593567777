import { FormMaker } from "formComponents"
import React, { useState } from "react"
import { statusStyles } from "shoppio-constants/Constants"
import { useStateValue } from "state"
import {
  Card,
  Row,
  Col,
  Button,
  Header,
  Space,
  Dialog,
  Toggle,
} from "uiComponents"
import { fields } from "./Schema"
import { useGetUserStores } from "firebaseUtils/firebaseOperations"
import { getTime } from "helpers"
import { useAlerter } from "hooks"
import "./AdBanner.css"

export const AdBanner = ({
  data,
  storeData,
  small,
  editable,
  adStatus,
  style,
  active,
}) => {
  const [dialog, setDialog] = useState()
  const [busy, setBusy] = useState(false)
  const [isActive, setIsActive] = useState(data?.adActive || false)

  const { updateUserStores } = useGetUserStores()

  const { alerter } = useAlerter()
  const handleUpdate = async values => {
    setBusy(true)
    await updateUserStores(storeData?.id, {
      marketing: {
        ...values,
        updatedAt: getTime(),
      },
    })
    alerter(
      `Ad details are updated successfully for ${storeData?.name} `,
      "success",
    )
    setBusy(false)
    setDialog(false)
  }
  const handleToggle = async () => {
    setBusy(true)
    const newActiveState = !isActive

    await updateUserStores(storeData?.id, {
      marketing: {
        adActive: newActiveState,
      },
    })

    setIsActive(newActiveState)
    alerter(`Ad status updated successfully for ${storeData?.name}`, "success")
    setBusy(false)
  }

  return (
    <>
      <Card rowStyle={{ ...style }}>
        <Row>
          <Col sm={12} md={1}>
            {editable && <p className='ad-banner-id'>ID: #892720</p>}
            <div>
              <img
                className={`profile-img ${small ? "small-size" : ""}`}
                src={storeData && storeData.logo}
                alt=''
              />
            </div>
          </Col>
          <Col sm={12} md={8} className='ad-banner-middle-content'>
            <div>
              <Header
                md={small ? false : true}
                sm={small ? true : false}
                bold
                accent
              >
                {storeData && storeData.name}
              </Header>
              <Header xl={small ? false : true} md={small ? true : false} bold>
                {data?.headline}
              </Header>
              <p className={`description-text ${small ? "small-size" : ""}`}>
                {data?.description}
              </p>
            </div>
            <div>
              {editable && (
                <>
                  <Space lg />
                  <Space lg />
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Button
                      noMar
                      fullRound
                      extraPad
                      onClick={() => setDialog(true)}
                    >
                      Edit Ad
                    </Button>
                    <Button noMar fullRound extraPad alt>
                      View Analytics
                    </Button>
                    {data?.status === "Completed" && active && (
                      <Toggle
                        active={isActive}
                        onClick={handleToggle}
                        activeLabel={"Active"}
                        label={"Inactive"}
                        horizontal
                      />
                    )}
                  </div>
                </>
              )}
            </div>
          </Col>
          <Col sm={12} md={3}>
            {adStatus && <AdStatus data={data} />}
            <div className={`image-container ${small ? "small-size" : ""}`}>
              <img
                className={`ad-img ${small ? "small-size" : ""}`}
                src={data?.banner}
                alt='Logo'
              />
            </div>
            <Space lg />
            <Button noMar small={true && small} fullRound fullWidth>
              {data?.button_text}
            </Button>
          </Col>
        </Row>
      </Card>
      <Dialog open={dialog} onClose={() => setDialog(false)}>
        <Header sm bold>
          Update Ad
        </Header>
        <FormMaker
          fields={fields}
          validateOnMount
          busy={busy}
          onSubmit={handleUpdate}
          initialValues={data}
          submitLabel={"Update Ad"}
        />

        <Space lg />
      </Dialog>
    </>
  )
}
export const AdBannerMobile = ({ data, small, editable }) => {
  const [{ currentStoreData }] = useStateValue()
  const { name: storeName, logo: storeLogo } = currentStoreData
  return (
    <>
      <Card notFull>
        <Row>
          <Col sm={12} md={12}>
            <p>ID: #892720</p>
            <div className={`image-container ${small ? "small-size" : ""}`}>
              <img
                className={`ad-img ${small ? "small-size" : ""}`}
                src={data?.banner}
                alt='Logo'
              />
            </div>
          </Col>
          <Space lg />
          <Col sm={12} md={12}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                className={`profile-img ${small ? "small-size" : ""}`}
                src={storeLogo}
                alt=''
              />
              <Header sm bold accent>
                {storeName}
              </Header>
            </div>
            <Header md bold>
              {data?.headline}
            </Header>
            <p className={`description-text ${small ? "small-size" : ""}`}>
              {data?.description}
            </p>
            {editable && (
              <>
                <Space lg />
                <Space lg />
                <div>
                  <Button noMar fullRound extraPad>
                    Edit Ad
                  </Button>
                  <Button noMar fullRound extraPad alt>
                    View Analytics
                  </Button>
                </div>
              </>
            )}
          </Col>
          <Col sm={12} md={12}>
            <Space lg />
            <Button small noMar fullRound fullWidth>
              {data?.button_text}
            </Button>
            <Space lg />
          </Col>
        </Row>
      </Card>
    </>
  )
}

const AdStatus = ({ data }) => {
  const status = data?.status
  return (
    <p
      className='ad-status'
      style={{
        backgroundColor: statusStyles[status].color,
      }}
    >
      {status}
    </p>
  )
}
